"use client"

import { useState } from "react"
import { Typewriter } from "react-simple-typewriter"
import { Square3Stack3DIcon } from "@heroicons/react/24/outline"
import { ChartBarSquareIcon } from "@heroicons/react/24/outline"
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { DocumentTextIcon } from "@heroicons/react/24/outline"
import { Footer } from "../footer"
import Link from "next/link"
import { ChevronRightIcon } from "@heroicons/react/20/solid"

export const Home: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)
  const images: string[] = [
    "/img/homepage/explain-world3.png",
    "/img/homepage/explain-climatechange2.png",
    "/img/homepage/explain-science.png",
    "/img/homepage/explain-research2.png",
    "/img/homepage/explain-economics.png",
    "/img/homepage/explain-politics.png",
    "/img/homepage/explain-osint.png",
    "/img/homepage/explain-opendata2.png",
    "/img/homepage/explain-technology.png",
  ]

  const handleType = (count: number) => {
    setCurrentImageIndex(count % images.length)
  }

  const features = [
    {
      name: "Create",
      description: "Create any view of your data, ideas, and synthesize analysis and research.",
      icon: Square3Stack3DIcon,
    },
    {
      name: "Visualize with AI",
      description: "Tell stories with data: visually engage your audience.",
      icon: ChartBarSquareIcon,
    },
    {
      name: "Verify",
      description: "Cite or verify information and sources to establish trust.",
      icon: DocumentMagnifyingGlassIcon,
    },
    {
      name: "Personalize and own",
      description: "Publish your own page or site, deliver to your subscribers, and collaborate on ideas.",
      icon: DocumentTextIcon,
    },
  ]

  return (
    <div className="bg-base-100 ">
      {/* hero section */}

      <div className="relative isolate pt-0 pb-10 md:pt-20 md:pb-28">
        <svg
          className="absolute -top-20 sm:-top-10 inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
        </svg>
        <div className="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            {/* <div className="flex">
              <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                <span className="font-semibold text-sky-600">We’re hiring</span>
                <span className="h-4 w-px bg-gray-900/10" aria-hidden="true" />
                <a href="#" className="flex items-center gap-x-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  See open positions
                  <ChevronRightIcon className="-mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </a>
              </div>
            </div> */}
            <div className="flex mt-20">
              <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-500 bg-gray-50">
                <div className="flex items-center gap-x-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  Beta
                  {/* <ChevronRightIcon className="-mr-2 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                </div>
              </div>
            </div>
            {/* <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              A better way to ship your projects
            </h1> */}
            <h1 className="text-5xl sm:text-6xl md:text-8xl font-bold tracking-tight font-serif mt-4">
              <span className="pb-2 inline-block">eхplain</span>
              <br />
              <span className="relative inline-block">
                <span className="relative">
                  {/* Style will be inherited from the parent element */}
                  <Typewriter
                    words={[
                      "the world",
                      "climate change",
                      "science",
                      "research",
                      "economics",
                      "politics",
                      "open source intelligence",
                      "open data",
                      "technology",
                      "the world",
                    ]}
                    loop={1}
                    cursor
                    cursorStyle="_"
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={2000}
                    onType={handleType}
                  />
                </span>
              </span>
            </h1>
            <p className="mt-6 text-xl lg:text-2xl leading-8 text-gray-600 max-w-full md:max-w-lg">
              Sens.center is a data and information publishing platform for building a sense of understanding of this
              complex world.
            </p>
            <div className="mt-6 md:mt-10 flex items-center gap-x-6">
              {/* <a
                href="#"
                className="rounded-md bg-sky-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Start now
              </a> */}
              <Link href="/app" className="btn btn-primary shadow-sm">
                Start now
                <ChevronRightIcon className="-mr-2 h-5 w-5 text-gray-50" aria-hidden="true" />
              </Link>
              {/* <Link href="#" className="text-sm font-semibold leading-6 text-gray-900">
                Learn more <span aria-hidden="true">→</span>
              </Link> */}
            </div>
          </div>
          <div className="mt-8 md:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow flex content-end justify-center lg:justify-end">
            <div>
              <img
                src={images[currentImageIndex]}
                alt="Typed Word"
                className="max-w-xs md:max-w-md rounded-lg border"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Feature section */}
      <section className="bg-neutral-50">
        <div className="mx-auto py-10 sm:py-24 max-w-7xl px-6">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-sky-600">Share your findings faster</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              An integrated suite of data tools.
            </p>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              Show your expertise. Collect and curate knowledge. Share it in seconds.
            </p>
          </div>
          <div className="mx-auto mt-8 md:mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl ">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-lg font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-sky-50">
                      <feature.icon className="h-6 w-6 text-sky-700" aria-hidden="true" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-lg leading-7 text-gray-600">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </section>

      {/* Diagram section */}

      <section className="bg-base-100 relative isolate overflow-hidden">
        <div className="max-w-7xl mx-auto relative flex flex-wrap sm:flex-nowrap px-6 lg:flex pt-4 pb-10 md:pt-20 md:pb-20">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-md lg:flex-shrink-0">
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Easily craft dynamic interfaces without writing a single line of code.
            </h2>
            <p className="mt-6 text-xl md:text-xl leading-8 text-gray-600">
              With options to link your own data or populate from public databases, you can focus on telling your story,
              presenting the facts, and curating knowledge.
            </p>
          </div>
          <div className="mx-auto mt-6 md:mt-16 flex max-w-2xl sm:mt-24 lg:ml-4 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-10">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <div className="-m-2 rounded-xl  p-2  lg:-m-4 lg:rounded-2xl lg:p-4">
                <img
                  src="/img/homepage/sens-all-01.png"
                  alt="App screenshot"
                  width={2432}
                  height={1442}
                  className="w-[76rem] rounded-md "
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-neutral-50">
        <div className="container mx-auto">
          <div className="max-w-screen-xl px-4 mx-auto lg:py-16 lg:px-6 pt-10 pb-10 md:pt-28 md:pb-28">
            <div className="max-w-lg mx-auto text-center">
              {/* <div className="card bg-base-100 border">
                <div className="card-body items-center text-center px-6 md:px-20">
                  <h2 className="mb-4 text-3xl md:text-5xl leading-tight tracking-tight">Sign up for public Beta</h2>
                  <p className="mb-2 font-light text-gray-900 text-2xl">
                    Subscribe now and become a part of our community. We'll notify you on the launch date.
                  </p>
                  <div className="mx-auto">
                    <iframe
                      className="sens-embed mt-2"
                      src="https://sens.center/share/l/sens-summary/subscribe-mini"
                      width="350"
                      height="100"
                      style={{ background: "transparent", border: "none" }}
                    ></iframe>
                  </div>
                </div>
              </div> */}
              <h2 className="font-bold text-2xl sm:text-4xl text-gray-800">Get started today</h2>
              <p className="mt-4 text-xl text-gray-700">
                Make your data and research have an impact. Start today and share your knowledge with the world.
              </p>
              <Link href="/app" className="mt-10 btn btn-primary">
                Get started for free
                <ChevronRightIcon className="-mr-2 h-5 w-5 text-gray-50" aria-hidden="true" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* footer */}
      <Footer />
    </div>
  )
}
